<template>
  <div>
    <!-- 如果是社会单位加样式society-bg -->
    <div :class="['company-bg', type==3?'society-bg':'']">
      <div class="container">
        <i class="iconfont icon-a-xingzhuang2"></i>
        <div class="detail-title"
             v-if="type == 1">国家级安全文化示范企业</div>
        <div class="detail-title"
             v-if="type == 3">社会单位文化建设示范</div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item">

              <router-link :to="{path: '/companylist', query: {type: type}}">
                <a>企业名录</a>
              </router-link>
            </li>
            <li class="breadcrumb-item active"
                aria-current="page">详情</li>
          </ol>
        </nav>
        <!-- 国家级和社会单位 -->
        <div class="panel panel-country"
             v-if="type!=2">
          <div class="company-name">{{type==3?info.title:info.name}}</div>
          <div class="name-box" v:if="type==1">
            <span class="name-country">{{info.named_at}}全国安全文化建设示范企业</span>
            <img
                src="../../assets/company/name-shape.png"
                class="name-shape"
              />
          </div>
          <div class="company-info">
            <div class="company-nature">{{info.tag}}</div>
            <!-- 还不知道社会单位的规则 -->
            <!-- <div v:if="type==3">取证年份 {{info.approve_year}}年</div> -->
          </div>
          <div class="rich-text">
            <!-- {{info.content}} -->
            {{decodeContent(info.content)}}
          </div>
        </div>
        <!-- 省级 -->
        <div class="detail-title"
             v-if="type==2">{{info.name}}</div>
             <div class="row">
              <div class="col-3">
        <div class="panel"
             v-if="type==2">
          <div class="panel-status">
            <div class="status-success"
                 v-if="info.recheck_status==1 && info.expired_at.length == 0">
              省级安全文化示范企业
            </div>
            <div class="status-fail"
                 v-if="info.recheck_status==2 && info.expired_at.length == 0">
              <span>{{info.recheck_time}}年度复审未通过，</span>
              <span>取消省级安全文化示范企业称号。</span>
            </div>
            <div class="status-expair"
                 v-if="info.expired_at > 0">
              <span>{{info.recheck_time}}年度未按要求重新申报</span>
              <span>取消省级安全文化示范企业称号</span>
            </div>
          </div>
          <div class="panel-item">
            <span class="item-label">隶属关系</span>
            <div class="company-nature">
              {{info.tag}}
            </div>
          </div>
          <div class="panel-item">
            <span class="item-label">地区</span>
            <span>{{info.province + info.city + info.district + info.street}}</span>
          </div>
          <div class="panel-item">
            <span class="item-label">行业</span>
            <span>{{info.industry}}</span>
          </div>
          <div class="line"></div>
          <div v-for="(item, idx) in info.approve_list"
               :key="item.approve_id">
            <div class="panel-item">
              <!-- <wxs src="../../../utils/wxs/tools.wxs" module="tools"></wxs> -->
              <!-- 只保证到五次取证 -->
              <div class="item-label">{{index2Chinese(idx)}}次取证时间</div>
              <div class="item-year">{{item.approve_year}}</div>
            </div>
            <div class="panel-item"
                 v-if="item.expired_at.length == 0 && item.recheck_history.length > 0">
              <span class="item-label">复审时间</span>
              <div class="item-values">
                <div v-for="approve in item.recheck_history"
                     :key="id"
                     class="item-value">
                  <span class="item-year">{{approve.year}}</span>
                  <div class="item-status"
                       v-if="approve.status=='1'">通过</div>
                  <div class="item-status item-status-expire"
                       v-if="approve.status=='2'"
                       @click="showReason(approve.reason)">未通过
                    <!-- <block wx:if="{{info.recheck_status=='2' && info.expired_at.length == 0}}"> -->
                    <span class="iconfont icon-bangzhu"></span>
                    <!-- </block> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="line"></div>
          </div>
          <div class="panel-item"
               v-if="info.next_check_year > 0">
            <div class="item-label">下次复审时间</div>
            <div class="item-year">{{info.next_check_year}}</div>
          </div>
          <!-- 或者 -->
          <div class="panel-item"
               v-if="info.recheck_year > 0">
            <div class="item-label">重新申报时间</div>
            <div class="item-year">{{info.recheck_year}}</div>
          </div>
        </div>
        </div>
        <div class="col-9">
          <div v-if="info.sections && info.sections.length > 0">
          <div class="panel panel-doc"
               data-bs-toggle="modal"
               data-bs-target="#ModalDoc"
               v-for="(item) in info.sections"
               :key="item.section_id"
               @click="showSectionContent(item)">
            <i class="iconfont icon-wenben"></i><span>{{item.sub_title}}</span>
          </div>
        </div>
        <div class="panel panel-intro"
             v-if="type==2">
          <div class="panel-title">企业简介</div>
          <div class="rich-text">
            {{info.intro}}
          </div>
        </div>
        </div>
  
      </div>

        <!-- 公共收藏 -->
        <!-- 公共收藏 -->
        <div :class="['fav', info.is_favourite==1?'active':'']"
             @click="toFavourite">
          <div class="fav-shape">
            <i class="iconfont icon-favourite_normal"></i>
          </div>
          收藏
        </div>
      </div>
    </div>

    <!-- 弹出层 -->
    <div class="modal fade"
         id="ModalDoc"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">
              <strong>{{curSection.sub_title}}</strong>
            </h1>
            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="rich-text">
              {{curSection.content}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Base64
} from "js-base64";
import {
  companyDetail,
  toPaperFavourite,
  compaynFavourite,
} from '@/network/API.js'
export default {
  data() {
    return {
      type: 1,
      info: {},
      curSection: {},
    }
  },
  async created() {
    if (this.$route.params.type) {
      this.type = this.$route.params.type
    }
    if (this.$route.params.id) {
      this.info = await companyDetail(this.$route.params.id)
    }
  },
  methods: {
    showSectionContent(item) {
      item.content = Base64.decode(item.content)
      this.curSection = item
    },
    async toFavourite() {
      if (this.type == 3) {
        let res = await toPaperFavourite({
          id: this.info.paper_id,
          code: "social",
        })
        this.info.is_favourite = res.is_favourite
      } else {
        let res = await compaynFavourite({
          id: this.info.company_id
        })
        this.info.is_favourite = res.is_favourite
      }
      if (this.info.is_favourite == 1) {
        this.$toast("收藏成功");
      } else {
        this.$toast("取消收藏");
      }
    }

  }
}
</script>
<style scoped>
.company-bg {
  padding-top: 30px;
}
/* .container {
  max-width: 768px;
} */
.breadcrumb-item.active,
.breadcrumb-item a,
.breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}

.panel {
  padding: 30px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.42) 0%,
    rgba(255, 255, 255, 0.4) 0%,
    #ffffff 99%
  );
  border-radius: 10px;
  margin-bottom: 30px;
}

.panel-status {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  font-weight: 700;
}

.status-success {
  color: #00be6e;
}

.status-fail,
.status-expair {
  display: flex;
  flex-direction: column;
}

.status-expair {
  color: #ff6d00;
}
.panel-title {
  font-size: 24px;
  font-weight: 600;
  color: #3b75ea;
  margin-bottom: 30px;
}

.panel-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 18px;
  color: #222;
  min-height: 44px;
  font-weight: 500;
  text-align: right;
}

.item-label {
  white-space: nowrap;
  padding-right: 30px;
  font-weight: 400;
  text-align: left;
}

.panel-doc {
  color: #3b75ea;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 15px 30px;
}

.panel-doc .iconfont {
  margin-right: 10px;
  font-size: 24px;
}

.company-name {
  font-size: 24px;
  font-weight: 500;
  color: #222;
  margin-bottom: 20px;
}

.detail-title {
  position: relative;
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
  z-index: 2;
  padding: 0 15%;
}

.detail-title::before,
.detail-title::after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  margin-top: -3px;
  width: 25%;
  height: 6px;
  transform: rotate(0deg);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 10px;
}

.detail-title::after {
  left: auto;
  right: 0;
  transform: rotate(180deg);
}

.company-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.company-nature {
  background: linear-gradient(294deg, #3b75ea -1%, #3b9ae4 100%);
  border-radius: 8px;
  height: 28px;
  line-height: 28px;
  padding: 0 16px;
  color: #fff;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.society-bg .company-nature {
  background: linear-gradient(106deg, #ffd993 0%, #ff6d00 99%);
}

.company-info text {
  font-size: 26px;
  display: inline-flex;
  color: #222;
  white-space: nowrap;
  margin-left: 20px;
}

.icon-a-xingzhuang2 {
  position: absolute;
  top: 15px;
  right: -50px;
  transform: rotate(35deg);
  font-size: 226px;
  color: #fff;
  opacity: 0.1;
  z-index: 1;
}

.modal-body {
  height: 80vh;
  overflow-y: auto;
}

.modal-body .rich-text {
  font-size: 16px;
  line-height: 1.8em;
}

.line {
  position: relative;
  height: 20px;
}

.line::before {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.42);
  transform: scaleY(0.5);
}

.item-values {
  display: flex;
  flex-direction: column;
}

.item-value {
  margin-bottom: 20px;
}

.item-status {
  display: inline-flex;
  margin-left: 40px;
  color: #00BE6E;
  width: 130px;
  align-items: center;
  justify-content: flex-end;
}

.item-status .iconfont {
  margin-left: 10px;
}

.item-status-expire {
  color: #FF3241;
}
.panel-intro,
.panel-country{
  min-height: 600px;
}
</style>